import { MDXProvider } from '@mdx-js/react'
import { FeatureSnapshot } from 'components/FeatureSnapshot'
import Hero from 'components/Hero'
import { Check, Close, LinkedIn } from 'components/Icons/Icons'
import Layout from 'components/Layout'
import Link from 'components/Link'
import { Section } from 'components/Section'
import { SEO } from 'components/seo'
import { SEO2 } from 'components/seo2'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import { MdxCodeBlock } from 'components/CodeBlock'
import { shortcodes } from '../../mdxGlobalComponents'
import { getSeoImage } from 'utils/googleData'
import { getDirection } from 'utils/translations'
const A = (props) => <Link {...props} className="font-semibold text-red hover:text-red" />

// export const Head = ({ location, params, data: { mdx }, pageContext }) => (
//     <SEO2
//         title={`Event ${mdx?.frontmatter?.title} `}
//         description={mdx?.frontmatter?.description || mdx?.excerpt}
//         image={mdx?.frontmatter?.ogImage?.publicURL || mdx?.parent?.footer?.image?.publicURL}
//     />
// )

export default function Event(pageProps) {
    const { data, location, pageContext, params, children } = pageProps
    const { pageData, blogPosts, documentationPosts, tutorialsPosts, tutorialsRecent, customersPosts } = data
    const { tableOfContents, menu, brand } = pageContext
    const { id, body, excerpt, fields, frontmatter, parent } = pageData
    const {
        eventDate,
        venue,
        from,
        to,
        city,
        year,
        title,
        agenda,
        speakers,
        speakersData,
        description,
        featuredImage,
        ogImage,
        seoDescription,
        seoTitle,
    } = frontmatter

    const ogImg = ogImage || parent?.footer?.image

    const components = {
        ...shortcodes(pageProps),
        pre: MdxCodeBlock,
        Hero,
        Section: (props) => <Section {...props} {...pageProps} />,
        FeatureSnapshot,
        Check,
        Close,
        a: A,
    }

    return (
        <Layout>
            <SEO
                title={seoTitle || `Event ${title} `}
                description={seoDescription || description || excerpt}
                image={getSeoImage({ googleImages: [parent?.images, parent?.images2] }) || ogImg?.publicURL}
            />
            <div className="mx-auto mt-14 max-w-[1070px] px-5">
                <article>
                    <section className="article-content">
                        <MDXProvider components={components}>
                            <MDXRenderer>{body}</MDXRenderer>
                        </MDXProvider>
                    </section>
                </article>
            </div>
        </Layout>
    )
}

//                date(formatString: "dddd, Do MMMM")
export const query = graphql`
    query EventQuery($id: String!) {
        pageData: mdx(id: { eq: $id }) {
            id
            body
            excerpt
            frontmatter {
                slug
                title
                description
                eventDate
                ogImage {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE)
                    }
                }
                featuredImage {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE)
                    }
                }
                venue {
                    name
                    address
                }
                agenda {
                    from
                    to
                    description
                    emoji
                }
                speakersData: speakerData {
                    frontmatter {
                        handle
                        company: company
                        linkedin
                        title: jobTitle
                        name
                        link_url
                        image {
                            childrenImageSharp {
                                gatsbyImageData(width: 100, height: 124)
                            }
                        }
                    }
                    id
                    name
                    parent {
                        ... on GoogleDocs {
                            places {
                                name
                                address
                            }
                            cover {
                                image {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(width: 100, height: 124)
                                    }
                                }
                            }
                        }
                    }
                }
                speakers {
                    name
                    title
                    company
                    linkedin
                    image {
                        childImageSharp {
                            gatsbyImageData(width: 100, height: 124)
                        }
                    }
                }
                city
                year
                seoTitle
                seoDescription
                seoimage
                from
                to
            }
            parent {
                ... on GoogleDocs {
                    id
                    images {
                        name
                        publicURL
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    images2: images {
                        name
                        publicURL
                        childImageSharp {
                            gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 }, breakpoints: [760])
                        }
                    }
                    downloadedFiles: downloadedFiles {
                        id
                        name
                        fileContent
                        absolutePath
                    }
                    cover {
                        image {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                    }
                    footer {
                        image {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                    }
                }
            }
        }
    }
`
